import { EntityType } from "../../Util/Constants/EntityType";
import SearchResultCard, {
  OrderSearchResult,
  OrganizationSearchResult,
  ParticipantSearchResult,
} from "./SearchResultCard";
import styles from "./SearchPage.module.css";
import { useSelector } from "react-redux";
import TextField from "../../Components/TextField";
import { Size } from "../../Util/Constants/Size";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import { useNavigate, useParams } from "react-router";
import { getSearchPath } from "../Path";
import {
  getOrdersIndex,
  getOrganizationsIndex,
  getParticipantsIndex,
} from "../../Store/InitialData/Selectors";
import { CircularProgress } from "@mui/material";

export default function SearchPage() {
  const navigate = useNavigate();
  const { q } = useParams();
  const searchText = decodeURI(q || "");
  const termToSearch = searchText.trim().toLowerCase();
  const organizationsIndex = useSelector(getOrganizationsIndex);
  const ordersIndex = useSelector(getOrdersIndex);
  const participantsIndex = useSelector(getParticipantsIndex);

  const organizationResults = organizationsIndex
    .filter(
      (organization) =>
        organization.organizationName?.toLowerCase().includes(termToSearch) ||
        organization.companyId?.toString().includes(termToSearch),
    )
    .map<OrganizationSearchResult>((organization) => ({
      entityType: EntityType.organization,
      id: organization.id,
      companyId: organization.companyId,
      organizationName: organization.organizationName,
    }));

  const orderResults = ordersIndex
    .filter(
      (order) =>
        order.id.toString().includes(termToSearch) ||
        order.organizationName?.includes(termToSearch),
    )
    .map<OrderSearchResult>((orderIndex) => ({
      entityType: EntityType.order,
      id: orderIndex.id,
      organizationName: orderIndex.organizationName,
      status: orderIndex.status,
      firstLectureDate: "",
    }));

  const participantsResult = participantsIndex
    .filter((participant) =>
      (participant.firstName + " " + participant.lastName)
        .toLowerCase()
        .includes(termToSearch),
    )
    .map<ParticipantSearchResult>((participant) => ({
      entityType: EntityType.publicCourseParticipant,
      id: participant.orderId,
      name: participant.firstName + " " + participant.lastName,
      orderId: participant.orderId,
      organizationName: participant.organizationName,
    }));

  return (
    <div className={styles.container}>
      <CustomSection title="חיפוש מתקדם">
        <TextField
          value={searchText}
          onChange={(newSearchText) => navigate(getSearchPath(newSearchText))}
          title="חיפוש"
          singelLine
          size={Size.X3L}
          autoFocus
        />
      </CustomSection>

      {termToSearch && ordersIndex.length === 0 && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}

      {termToSearch && ordersIndex.length > 0 && (
        <>
          <div className={styles.groupTitle}>ארגונים</div>
          <div className={styles.searchResultGroup}>
            {organizationResults.slice(0, 10).map((org) => (
              <SearchResultCard {...org} key={"org." + org.id} />
            ))}
          </div>

          <div className={styles.groupTitle}>הזמנות</div>
          <div className={styles.searchResultGroup}>
            {orderResults.slice(0, 15).map((order) => (
              <SearchResultCard {...order} key={"order." + order.id} />
            ))}
          </div>

          <div className={styles.groupTitle}>משתתפים בקורס ציבורי</div>
          <div className={styles.searchResultGroup}>
            {participantsResult.slice(0, 15).map((participant) => (
              <SearchResultCard
                {...participant}
                key={"order." + participant.id + ".par." + participant.name}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
